<template>
	<div class="mb-30">
		<label class="group block mb-5" :for="`input-${id}`">{{ label }}<span v-if="required" class="color-red" style="margin-left: 2.5px">*</span></label>

		<div v-if="sublabel" class="sublabel mb-10 color-grey">{{ sublabel }}</div>

		<input
			:type="type"
			:class="{ small: small }"
			class="focus:background-light-grey-100 with-placeholder"
			:id="`input-${id}`"
			:value="value"
			@input="input"
			@blur="$emit('blur', $event)"
			:disabled="disabled"
			:placeholder="placeholder"
		/>

		<div class="small-text color-red mt-10 px-5" v-if="error" v-text="errorMessage"></div>
	</div>
</template>

<script>
export default {
	name: 'InputGroup',

	props: {
		value: [String, Number],
		label: String,
		sublabel: String,
		type: {
			type: String,
			default: 'text',
		},
		small: Boolean,
		error: Boolean,
		errorMessage: String,
		required: Boolean,
		placeholder: String,
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			id: null,
		};
	},

	mounted() {
		this.id = this._uid;
	},

	methods: {
		input(e) {
			this.$emit('input', e.target.value);
		},
	},
};
</script>

<style lang="scss" scoped>
.sublabel {
	font-size: 13px;
}
</style>
